import React from "react";
import styled from "@emotion/styled";
import menuBackground from "./menu/MenuBackground.svg";
import geriactLogo from "./menu/geriact_splash_screen.png";
import HamburgerMenu from "./HamburgerMenu";

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(${menuBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const SplashImage = styled.img`
  width: 50%;
  max-width: 800px;
  height: auto;
  margin-bottom: 32px;
`;

const CompletionCard = styled.div`
  background-color: #011e2d;
  color: white;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 600px;
  width: 90%;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
`;

const Message = styled.p`
  font-size: 18px;
  line-height: 1.5;
`;

const Button = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: #011e2d;
  &:hover {
    background-color: #0f2432;
  }
`;

export const GameCompleteScreen: React.FC<{ onComplete: () => void }> = ({
  onComplete,
}) => {
  return (
    <>
      <BackgroundWrapper />
      <Container>
        <HamburgerMenu />
        <SplashImage src={geriactLogo} alt="GeriACT Splash Screen" />
        <CompletionCard>
          <Title>Congratulations!</Title>
          <Message>
            You have completed the game. Please take the Post-game Assessment
            now.
          </Message>

          <SecondaryButton onClick={() => onComplete()}>
            Back to Game Menu
          </SecondaryButton>
          <a href="https://und.qualtrics.com/jfe/form/SV_2gVPdo48YVOWAYK">
            <Button>Take Post-game Assessment</Button>
          </a>
        </CompletionCard>
      </Container>
    </>
  );
};

export default GameCompleteScreen;
