import React, { ReactNode, useMemo } from "react";
import geriactBackground from "./game/start/geriact-title-screen.jpg";
import "./firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import StyledFirebaseAuth from "./FirebaseAuth";
import { Navigate, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Alert, Card } from "react-bootstrap";

export const Login: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();
  const [currentUser, setCurrentUser] = React.useState<firebase.User | null>(
    null
  );

  const auth = useMemo(() => {
    const auth = firebase.auth();
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    auth.onAuthStateChanged((user) => {
      console.info("onAuthStateChanged", user);
      setCurrentUser(user);
    });
    return auth;
  }, []);

  if (currentUser) {
    console.info("Logged in");
    return <> {children}</>;
  }

  const uiConfig: firebaseui.auth.Config = {
    callbacks: {
      signInFailure: (error) => {
        console.error("signInFailure", error);
      },

      signInSuccessWithAuthResult: (authResult: any) => {
        console.info("signInSuccessWithAuthResult", authResult);
        return false;
      },
      uiShown: () => {
        console.info("uiShown");
      },
    },
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    //signInSuccessUrl: pathname,
    // We will display Google and Facebook as auth providers.
    // signInSuccess: (user: firebase.User) => {
    //   setCurrentUser(user);
    // },
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
  };

  return (
    <FullScreenDiv>
      <Card bg="light" text="dark">
        <Card.Body>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
          <Alert variant="info" style={{ marginTop: 10 }}>
            If you have completed a pre-play survey, please use the same email
            address.
          </Alert>
        </Card.Body>
      </Card>
    </FullScreenDiv>
  );
};

const FullScreenDiv = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${geriactBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding-top: 100px;
  overflow: hidden;
`;
