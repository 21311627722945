import { firestore } from '../firebase';
import { doc, setDoc, getDoc, arrayUnion, updateDoc, collection, query, limit, getDocs, startAfter, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { GameState } from './gameStateAtom';
import { auth } from '../firebase';



interface ArchivedGameState extends GameState {
    archivedAt: Date;
}

// interface ArchivedGames {
//     games: ArchivedGameState[];
// }

export interface SavedGamesResponse {
    games: (GameState & { userId: string })[];
    lastDoc: QueryDocumentSnapshot<DocumentData> | null;
    hasMore: boolean;
}

export const archiveGameState = async (gameState: GameState): Promise<void> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error('No user logged in');
    }

    const archivedDocRef = doc(firestore, 'archivedGames', user.uid);
    console.info('Archived game state doc ref:', archivedDocRef);
    const toArchive: ArchivedGameState = {
        archivedAt: new Date(),
        ...gameState
    }

    try {
        const docSnap = await getDoc(archivedDocRef);
        if (docSnap.exists()) {
            console.info('Archived game state already exists');
            await updateDoc(archivedDocRef, {
                games: arrayUnion(toArchive)
            });
        } else {
            throw new Error('Archived game state does not exist');
        }
    } catch (error) {
        console.info('Archived game state does not exist');
        await setDoc(archivedDocRef, {
            games: [
                toArchive
            ]
        });
    }






}

export const saveGameState = async (gameState: GameState): Promise<void> => {
    const user = auth.currentUser;
    debugger
    if (!user) {
        throw new Error('No user logged in');
    }
    

    const userDocRef = doc(firestore, 'savedGames', user.uid);

    try {
        await setDoc(userDocRef, gameState);
        console.log('Game state saved successfully');
    } catch (error) {
        console.error('Error saving game state:', error);
        throw error;
    }
};

export const loadGameState = async (): Promise<GameState | null> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error('No user logged in');
    }

    const userDocRef = doc(firestore, 'savedGames', user.uid);

    try {
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            return docSnap.data() as GameState;
        } else {
            console.log('No saved game state found');
            return null;
        }
    } catch (error) {
        console.error('Error loading game state:', error);
        throw error;
    }
};

export const getSavedGames = async (
    pageSize: number = 10,
    startAfterDoc?: QueryDocumentSnapshot<DocumentData>
): Promise<SavedGamesResponse> => {
    const savedGamesRef = collection(firestore, 'savedGames');
    let q = query(savedGamesRef, limit(pageSize + 1)); // Get one extra to check if there are more

    if (startAfterDoc) {
        q = query(savedGamesRef, startAfter(startAfterDoc), limit(pageSize + 1));
    }

    try {
        const querySnapshot = await getDocs(q);
        const games: (GameState & { userId: string })[] = [];
        let lastVisible: QueryDocumentSnapshot<DocumentData> | null = null;
        let count = 0;

        querySnapshot.forEach((doc) => {
            if (count < pageSize) {
                games.push({
                    ...doc.data() as GameState,
                    userId: doc.id
                });
                lastVisible = doc;
            }
            count++;
        });

        return {
            games,
            lastDoc: lastVisible,
            hasMore: querySnapshot.size > pageSize
        };
    } catch (error) {
        console.error('Error loading saved games:', error);
        throw error;
    }
};
