import React from "react";
import styled from "@emotion/styled";

const Column = styled.td`
  min-width: 250px;
  font-size: 8px;
  flex: 1;
`;

const DialogList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const DialogItem = styled.li`
  padding: 1px 0;
  border-bottom: 1px solid #f5f5f5;
`;

interface DialogLogProps {
  dialogLog: Record<string, string[]>;
  dialogId: string;
}

export const DialogLogColumn = ({ dialogLog, dialogId }: DialogLogProps) => {
  if (!dialogLog || typeof dialogLog !== "object") {
    return <td>n/a</td>;
  }

  const responses = dialogLog[dialogId];
  if (!responses) {
    return <td>n/a</td>;
  }

  return (
    <>
      <Column>
        <DialogList>
          {responses.map((response, index) => (
            <DialogItem key={index}>{response}</DialogItem>
          ))}
        </DialogList>
      </Column>
    </>
  );
};

export default DialogLogColumn;
