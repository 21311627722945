import { useEffect, useState, useMemo } from "react";
import styled from "@emotion/styled";
import { getSavedGames, SavedGamesResponse } from "../state/saveGameService";
import { DialogLogColumn } from "./DialogLogColumns";
import { GameState } from "../state/gameStateAtom";
import { gameStages } from "../game/GameStages";
const GridContainer = styled.div`
  .ag-theme-quartz {
    --ag-foreground-color: rgb(14, 68, 145);
    --ag-background-color: rgb(241, 247, 255);
    --ag-header-background-color: rgb(228, 237, 250);
    --ag-row-hover-color: rgb(216, 226, 255);
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  background-color: rgb(241, 247, 255);
`;

const Th = styled.th`
  background-color: rgb(228, 237, 250);
  color: rgb(14, 68, 145);
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
  vertical-align: top;
  max-height: 100px;
  overflow: auto;
  &:hover {
    background-color: rgb(216, 226, 255);
  }
`;

const dialogs = [
  "core1-step2",
  "core1-step3",
  "core2-step1",
  "core2-tutorial1",
  "core2-step2",
  "core2-step3",
  "core2-tutorial2",
  "core2-step4",
  "core2-step5",
  "core2-step6",
];

const dialogFlags = [
  "asked_vaccines",
  "as_needed",
  "insight",
  "got_it",
  "gait",
  "guess",
  "nurse",
  "reduced_alcohol",
  "asked_alcohol",
  "wm_ind",
  "bauInsight",
  "next_mentation",
  "wm_count",
  "pims",
  "choose_cog",
  "med_check",
  "num_asked",
  "same",
  "men_check",
  "completed_tut",
  "mob_check",
  "med_extend_tram",
  "protein_tip",
  "stated",
  "asked_diet",
  "wm_first",
  "showed_plan",
];

const getCurrentStepLabel = (game: GameState) => {
  const savedStage = game.stages.find((s) => s.id === game.currentStage);
  const currentStep = savedStage?.currentStep || 0;

  const stage = gameStages.find((s) => s.id === game.currentStage);

  return stage?.steps[currentStep].label;
};

// Get all stage IDs and their steps


export const UserReport = () => {
  const [response, setResponse] = useState<SavedGamesResponse>();

  const stageSteps = useMemo(() => {
    return gameStages.flatMap((stage) =>
      stage.steps.map((step) => ({
        stageId: stage.id,
        stepId: step.id,
        label: step.label,
      }))
    );
  }, []);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const response = await getSavedGames(1000);
        console.log(response);
        setResponse(response);
      } catch (error) {
        console.error("Error loading saved games:", error);
      }
    };

    loadInitialData();
  }, []);

  if (!response) {
    return null;
  }

  return (
    <GridContainer>
      <Table>
        <thead>
          <tr>
            <Th>User ID</Th>
            <Th>Last Step</Th>
            <Th>Insight</Th>
            {/* Add timestamp columns */}
            {dialogs.map((dialogId) => (
              <Th key={dialogId}>{dialogId}</Th>
            ))}
            {dialogFlags.map((flag) => (
              <Th key={flag}>{flag}</Th>
            ))}
            {stageSteps.map(({ stepId, label }) => (
              <Th key={stepId}>{`${label} Time`}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {response.games.map((game, index) => (
            <tr key={index}>
              <Td>{game.userId}</Td>
              <Td>{getCurrentStepLabel(game)}</Td>
              <Td>{game.dialogContext.insight}</Td>              

              {dialogs.map((dialogId) => (
                <DialogLogColumn
                  key={dialogId}
                  dialogLog={game.dialogLog}
                  dialogId={dialogId}
                />
              ))}
              {dialogFlags.map((flag) => (
                <Td key={flag}>{"" + game.dialogContext.Flags[flag]}</Td>
              ))}
              {stageSteps.map(({ stepId }) => {
                const timestamp = game.timestamps?.find(
                  (t) => t.id === `${stepId}-completed`
                )?.timestamp;
                return (
                  <Td key={stepId}>
                    {timestamp ? new Date(timestamp).toLocaleString() : ""}
                  </Td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </GridContainer>
  );
};
